import * as React from "react";
import { useState } from "react";
import RegistrationForm from "./RegistrationForm";

const AgendaItem = ({
  title,
  author,
  bio,
}: {
  title: string;
  author: string;
  bio: string;
}) => (
  <div className="mb-5">
    <h2 className="mb-2 text-2xl font-semibold font-heading">{title}</h2>
    <p className="text-lg text-gray-800">
      {author} <small className="text-gray-500">{bio}</small>
    </p>
  </div>
);

export type State = "NEW" | "IN_PROGRESS" | "REGISTERED" | "CLOSED";

const currentWebinarData = {
  title: "Kolka niemowlęca",
  author: "dr n. med. Izabela Jastrzębska",
  bio: "Pediatra",
  date: "Wtorek, 8 Luty",
  time: "godzina 18:30",
  /* calendar links generator: https://www.labnol.org/apps/calendar.html */
  googleCalendarLink:
    "https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20220208T173000Z%2F20220208T181500Z&location=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DOlP1AMVDJcU&text=Kolka%20niemowl%C4%99ca%20-%20dr%20n.%20med.%20Izabela%20Jastrz%C4%99bska%2C%20Pediatra",
  outlookCalendarLink:
    "https://outlook.office.com/calendar/0/deeplink/compose?allday=false&enddt=2022-02-08T18%3A15%3A00%2B00%3A00&location=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DOlP1AMVDJcU&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2022-02-08T17%3A30%3A00%2B00%3A00&subject=Kolka%20niemowl%C4%99ca%20-%20dr%20n.%20med.%20Izabela%20Jastrz%C4%99bska%2C%20Pediatra",
};

const SignUp = () => {
  const [state, setState] = useState<State>("NEW");

  return (
    <>
      <section className="relative lg:py-20 bg-blue-800" id="signup">
        <div className="absolute top-0 left-0 lg:bottom-0 h-128 lg:h-auto w-full lg:w-8/12 bg-white" />
        <div className="relative container px-4 mx-auto ">
          <div className="flex flex-wrap items-center -mx-4">
            <div className="w-full lg:w-1/2 px-4 lg:mb-0">
              <div className="max-w-lg">
                <span className="inline-block mb-4 text-sm text-blue-800 font-semibold">
                  Program webinaru
                </span>
                <AgendaItem
                  title={currentWebinarData.title}
                  author={currentWebinarData.author}
                  bio={currentWebinarData.bio}
                />
              </div>
            </div>
            <div className="w-full lg:w-1/2 px-4" id="signupForm">
              <div className="p-6 my-5 lg:px-10 lg:py-12 bg-white text-center border rounded-xl">
                {state !== "CLOSED" && (
                  <>
                    <h3 className="mb-2 text-2xl font-semibold font-heading">
                      {currentWebinarData.date}
                    </h3>
                    <h4 className=" text-1xl font-semibold font-heading">
                      {currentWebinarData.time}
                    </h4>
                  </>
                )}
                {state === "REGISTERED" && (
                  <>
                    <h3 className="mt-6 text-2xl text-red-200">
                      Wszystko się udało!
                    </h3>
                    <span className="mt-6 inline-block text-gray-700">
                      Sprawdź swoją skrzynkę email.
                    </span>

                    <h2 className="mt-6 mb-6 text-xl font-semibold font-heading">
                      Dodaj wydarzenie do swojego kalendarza
                    </h2>
                    <a
                      href={currentWebinarData.googleCalendarLink}
                      className=" mr-3 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                    >
                      Kalendarz Google
                    </a>

                    <a
                      href={currentWebinarData.outlookCalendarLink}
                      className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                    >
                      Outlook
                    </a>
                  </>
                )}
                {(state === "NEW" || state === "IN_PROGRESS") && (
                  <RegistrationForm state={state} setState={setState} />
                )}

                {state === "CLOSED" && (
                  <>
                    <h3 className="mb-2 text-2xl font-semibold font-heading">
                      Webinar już się zakończył!
                    </h3>
                    <a
                      href="https://webinar.klubszczesliwejmamy.pl/live"
                      className={`mt-6 w-full inline-block py-4 mb-4 text-sm text-white font-medium leading-normal bg-red-300 hover:bg-red-200 rounded transition duration-200`}
                    >
                      Zobacz nagranie spotkania
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignUp;
