import * as React from "react";
import { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";

const TopBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <section className="py-8 px-4 xl:px-10" id="start">
      <nav>
        <div className="flex justify-between items-center">
          <a className="text-lg font-medium" href="/">
            <StaticImage
              quality={100}
              placeholder="none"
              src="../images/ksm_logo.png"
              alt="Klub Szczęśliwej Mamy"
              width={150}
            />
          </a>
          <div className="lg:hidden">
            <button
              onClick={() => setMenuOpen(true)}
              className="navbar-burger flex items-center p-3 hover:bg-gray-50 rounded"
            >
              <svg
                className="block h-4 w-4"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Mobile menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
          <ul className="hidden lg:flex lg:ml-auto lg:mr-12 lg:items-center lg:w-auto lg:space-x-12">
            <li>
              <a className="font-medium text-sm" href="/#agenda">
                Program webinaru
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <div className={`navbar-menu relative z-50 ${menuOpen ? "" : "hidden"}`}>
        <div
          className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25"
          onClick={() => setMenuOpen(false)}
        />
        <nav className="fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-white border-r overflow-y-auto">
          <div className="flex items-center mb-8">
            <a className="mr-auto text-2xl font-medium leading-none" href="/">
              <StaticImage
                src="../images/ksm_logo.png"
                alt="Klub Szczęśliwej Mamy"
                width={150}
              />
            </a>
            <button className="navbar-close" onClick={() => setMenuOpen(false)}>
              <svg
                className="h-6 w-6 text-gray-500 cursor-pointer hover:text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div>
            <ul>
              <li className="mb-1">
                <a
                  className="block p-4 text-sm font-medium text-gray-900 hover:bg-gray-50 rounded"
                  href="/#intro"
                  onClick={() => setMenuOpen(false)}
                >
                  Co czeka na Ciebie w klubie
                </a>
              </li>
              <li className="mb-1">
                <a
                  className="block p-4 text-sm font-medium text-gray-900 hover:bg-gray-50 rounded"
                  href="/#agenda"
                  onClick={() => setMenuOpen(false)}
                >
                  Program webinaru
                </a>
              </li>
            </ul>
          </div>
          <div className="mt-auto">
            <div className="pt-6">
              {/*<JoinClubButton onClick={() => setMenuOpen(false)} />*/}
            </div>
            <p className="mt-6 mb-4 text-sm text-center text-gray-500">
              <span>© 2021 All rights reserved.</span>
            </p>
          </div>
        </nav>
      </div>
    </section>
  );
};

export default TopBar;
