import React, { useEffect } from "react";
import TopBar from "../components/TopBar";
import SignUp from "../components/SignUp";
import Agenda from "../components/Agenda";
//@ts-ignore
import KsmCard from "../static/images/ksm_card.jpg";
import { Helmet } from "react-helmet";

export default function IndexRoute() {
  return (
    <>
      <Helmet
        title="Webinar | Klub Szczęśliwej Mamy"
        bodyAttributes={{
          class: "antialiased bg-body text-body font-body",
        }}
        defer={false}
      >
        <meta
          property="og:url"
          content="https://webinar.klubszczesliwejmamy.pl"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Webinar | Klub Szczęśliwej Mamy" />
        <meta
          property="og:description"
          content="Najbliższy publiczny webinar organizowany w ramach Klubu Szczęśliwej Mamy"
        />
        <meta
          property="og:image"
          content={`https://webinar.klubszczesliwejmamy.pl${KsmCard}`}
        />
      </Helmet>
      <TopBar />
      <SignUp />
      {/* <Agenda /> */}
      <section className="py-10 ">
        <p className="text-center text-sm text-gray-500 pt-8 px-4 border-t">
          All rights reserved © Koncept Bobo 2021
        </p>
      </section>
      <div className="fixed bottom-3 right-3 sm:invisible">
        <a
          href="/#start"
          className=" inline-block p-1 bg-red-200 rounded-full hover:bg-red-700 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="35"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path
              fill="#FFFFFF"
              d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"
            />
          </svg>
        </a>
      </div>
    </>
  );
}
