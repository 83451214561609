import React, { useState } from "react";
import { State } from "./SignUp";

const subscribe = async (email: string, name: string) => {
  await fetch("https://2a17d679c6611608b7c943ac6868d059.m.pipedream.net", {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify({ email, name }),
  });
};

const RegistrationForm = ({
  state,
  setState,
}: {
  state: State;
  setState: (state: State) => void;
}) => {
  const [rodoChecked, setRodoChecked] = useState(false);
  const [email, setEmail] = useState<null | string>(null);
  const [name, setName] = useState<null | string>(null);

  return (
    <form
      className="mt-12"
      action="#"
      onSubmit={async (e) => {
        e.preventDefault();
        if (!email || email === "" || !name || name === "") {
          return;
        }
        setState("IN_PROGRESS");
        await subscribe(email, name);
        setState("REGISTERED");
      }}
    >
      <div className="relative flex flex-wrap mb-3">
        <input
          className="relative mb-2 md:mb-0 w-full py-4 pl-4 text-sm border rounded"
          type="name"
          required
          onChange={(event) => setName(event.target.value)}
        />
        <span className="absolute top-0 left-0 ml-4 -mt-2 px-1 inline-block bg-white text-gray-500 text-xs">
          Imię
        </span>
      </div>
      <div className="relative flex flex-wrap mb-3">
        <input
          className="relative mb-2 md:mb-0 w-full py-4 pl-4 text-sm border rounded"
          type="email"
          required
          placeholder="np. monika@poczta.pl"
          onChange={(event) => setEmail(event.target.value)}
        />
        <span className="absolute top-0 left-0 ml-4 -mt-2 px-1 inline-block bg-white text-gray-500 text-xs">
          Adres email
        </span>
      </div>
      <div className="relative flex flex-wrap mb-3">
        <label className="inline-flex items-center">
          <input
            type="checkbox"
            className="form-checkbox"
            checked={rodoChecked}
            required
            style={{ minWidth: "25px" }}
            onChange={() => setRodoChecked(!rodoChecked)}
          />
          <span className="ml-2 text-justify text-xs">
            Zapisując się na powiadomienie o webinarze wyrażasz zgodę na
            przesyłanie Ci informacji związanych z Klubem Szczęśliwej Mamy.
            Szczegóły związane z przetwarzaniem danych osobowych znajdziesz w{" "}
            <a
              className="text-blue-800 italic font-bold"
              href="https://klubszczesliwejmamy.pl/polityka_prywatnosci_13_06_2021.pdf"
            >
              polityce prywatności
            </a>
          </span>
        </label>
      </div>
      <button
        disabled={state === "IN_PROGRESS"}
        className={`disabled:opacity-50 w-full inline-block py-4 mb-4 text-sm text-white font-medium leading-normal bg-red-300 hover:bg-red-200 rounded transition duration-200`}
      >
        {state === "NEW" && `Powiadom mnie o webinarze!`}
        {state === "IN_PROGRESS" && `Jeszcze chwileczkę...`}
      </button>
    </form>
  );
};

export default RegistrationForm;
